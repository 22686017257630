<template>
    <div id="couponsIndexPage">
      <img class="bg" src="/static/images/bg_01.png">
      <div class="container">
        <div class="section section-1">
          <div class="section-title">
            <div class="name">新会員限定ギフト</div>
          </div>
          <div class="section-content">
            <ul class="list">
              <li class="list-item" v-for="item in allCouponList" :key="item.id">
                <div class="content">
                  <div class="content-left">
                    <div class="num">{{ item.price }}円</div>
                    <div class="tip">{{ item.typeName }}</div>
                  </div>
                  <div class="content-right">
                    <div class="left">
                      <div class="title text-ellipsis">{{ item.name }}</div>
                      <div class="date text-ellipsis" v-if="item.validityType === 2">有効期限：{{ item.validityEndTime }}</div>
                      <div class="date text-ellipsis" v-else>受領後{{item.day}}日間有効</div>
                    </div>
                    <div class="right">
                      <template  v-if="item.haveNumber===0 || item.haveNumber===null">
                        <div class="cet" v-show="item.percentage !== 0">
                          <van-circle
                            :value="setCircle(item.percentage, 100)"
                            color="#E98D44"
                            layer-color="#F5ECEB"
                            :stroke-width="70"
                          >
                            <div class="circle-content">
                              <div class="name">未受領</div>
                              <!-- <div class="num">{{setCircleVal(80, 100)}}％</div> -->
                              <div class="num">{{ item.percentage }}％</div>
                            </div>
                          </van-circle>
                          <button type="button" class="btn" @click="receive(item)">受領</button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="cet" v-show="item.percentage !== 0">
                          <i class="icon icon-1"></i>
                          <button type="button" class="btn" @click="gotoUse">使う</button>
                        </div>
                      </template>
                      <template v-if="item.percentage === 0">
                        <i class="icon icon-2"></i>
                        <button type="button" class="btn disabled">在庫切れ</button>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="before"></div>
                <div class="after"></div>
              </li>
            </ul>
          </div>
        </div>
        <div class="section section-2">
          <div class="section-title">
            <div class="name">利用説明</div>
          </div>
          <div class="section-content">
            <ul class="list">
              <li class="list-item" v-for="(item, index) in navList" :key="index">
                <i class="icon" :class="[`icon-${index+1}`]"></i>
                <div class="name">{{item}}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { Circle } from 'vant';
export default {
  name: 'CouponsIndex',
  components: {
    'van-circle': Circle
  },
  data () {
    return {
      allCouponList: [],
      navList: ['VIP/HD動画を見る', '動画詳細', '購入', 'クーポンを選ぶ', '決定']
    };
  },
  created () {
    this.allCouponDetail();
  },
  mounted () {
  },
  watch: {},
  methods: {

    /* 获取所有的优惠券信息 */
    allCouponDetail () {
      let { $http } = this;
      $http.get('discount/list').then((res) => {
        const { list } = res.data;
        this.allCouponList = list.map((item) => item);
      }).catch((_v) => {
      });
    },

    /* 领取优惠券 */
    receive (item) {
      const { $http } = this;
      const { id } = item;
      if (id) {
        $http.post('discount/drawDiscount', {
          id
        }).then(() => {
          this.$utils.successToast({
            message: '受取成功',
            onClose: () => {
              this.allCouponDetail();
            }
          });
        });
      }
    },

    /* 去使用 */
    gotoUse () {
      const { $utils, $router } = this;
      $utils.toast({
        message: '待っています...',
        duration: 200,
        onClose: () => {
          $router.push({
            name: 'Home'
          });
        }
      });
    },
    setCircleVal (val, total) {
      return (val > total ? 1 : (val / total)) * 100 || 0;
    },
    setCircle (val, total) {
      return this.setCircleVal(val, total) * 0.7 + 15;
    }
  }
};
</script>

<style scoped lang="less">
  #couponsIndexPage {
    position: relative;
    max-width: 750px;
    margin: auto;

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
    }

    .container {
      position: relative;
      z-index: 1;
      padding: 416px 20px 42px;

      .section {
        position: relative;
        padding: 86px 22px 66px;
        background: #fff;
        border-radius: 16px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);

        &:not(:last-child) {
          margin-bottom: 124px;
        }

        .section-title {
          position: absolute;
          top: 0;
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 552px;
          height: 77px;
          font-size: 32px;
          font-weight: bold;
          background: url("~@/assets/images/bg_14.png") center / contain no-repeat;
          transform: translate(-50%, -50%);
        }

        &.section-1 {
          .section-content {
            .list {
              .list-item {
                position: relative;

                &:not(:last-child) {
                  margin-bottom: 20px;
                }

                .content {
                  display: flex;
                  height: 166px;
                  overflow: hidden;
                  background-color: #fff;
                  border-radius: 8px;
                  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.06);

                  .content-left,
                  .content-right {
                    display: flex;
                    align-items: center;
                  }

                  .content-left {
                    flex-direction: column;
                    flex-shrink: 0;
                    justify-content: center;
                    width: 174px;
                    padding: 20px;
                    background-color: #e98d44;

                    .num {
                      font-size: 44px;
                      white-space: nowrap;
                      .text{
                        font-size: 44px;
                      }
                    }

                    .tip {
                      font-size: 20px;
                      line-height: 28px;
                      .text-overflow();
                    }
                  }

                  .content-right {
                    &,
                    .left {
                      flex: 1;
                      width: 0;
                    }

                    .left {
                      padding: 20px 0 20px 44px;
                      line-height: 32px;
                      color: #232324;

                      & > :not(:last-child) {
                        margin-bottom: 14px;
                      }

                      .title {
                        font-size: 26px;
                        font-weight: bold;
                        line-height: 32px;
                      }

                      .date {
                        font-size: 18px;
                        line-height: 24px;
                        opacity: 0.6;
                      }
                    }

                    .right {
                      display: flex;
                      flex-direction: column;
                      flex-shrink: 0;
                      align-items: center;
                      justify-content: center;
                      padding: 16px;
                      font-size: 24px;
                      .cet{
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                      }
                      .van-circle {
                        width: 104px;
                        height: 104px;

                        &,
                        .circle-content {
                          transform: rotateZ(180deg);
                        }

                        .circle-content {
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                          height: 100%;
                          padding: 10px 10px 24px;
                          font-size: 18px;
                          color: #e98d44;
                          opacity: 0.6;
                        }
                      }

                      .icon {
                        width: 124px;
                        height: 110px;

                        &.icon-1 {
                          background-image: url("~@/assets/images/bg_status_01.png");
                        }

                        &.icon-2 {
                          background-image: url("~@/assets/images/bg_status_02.png");
                        }
                      }

                      .btn {
                        position: relative;
                        z-index: 1;
                        width: 146px;
                        height: 54px;
                        margin-top: -28px;
                        background-color: #e98d44;
                        border-radius: 30px;

                        &.disabled {
                          background-color: #f5eceb;
                        }
                      }
                    }
                  }
                }

                .before,
                .after {
                  position: absolute;
                  left: 174px;
                  z-index: 1;
                  width: 36px;
                  height: 36px;
                  content: '';
                  background-color: #fff;
                  border-radius: 100%;
                  box-shadow: inset 0 6px 12px rgba(0, 0, 0, 0.06);
                  transition: all 0.3s;
                  transform: translate(-50%, -50%);

                  &::after {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 50%;
                    content: '';
                    background-color: #fff;
                  }
                }

                .before {
                  &,
                  &::after {
                    top: 0;
                  }
                }

                .after {
                  top: 100%;

                  &::after {
                    bottom: 0;
                  }
                }
              }
            }
          }
        }

        &.section-2 {
          padding-top: 32px;

          .section-content {
            font-size: 24px;
            line-height: 32px;
            color: #6466ee;

            .list {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;

              .list-item {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: calc(33.33% - 18px);
                margin-top: 68px;

                &:not(:last-child) {
                  margin-right: 27px;

                  &::after {
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    width: 0;
                    height: 0;
                    content: '';
                    border-top: 13.5px solid transparent;
                    border-bottom: 13.5px solid transparent;
                    border-left: 27px solid #6466ee;
                    transform: translateY(-50%);
                  }
                }

                &:nth-of-type(3n) {
                  margin-right: 0;

                  &::after {
                    display: none;
                  }
                }

                .icon {
                  width: 120px;
                  height: 120px;
                  margin-bottom: 6px;

                  &.icon-1 {
                    background-image: url("~@/assets/images/icon_step_01.png");
                  }

                  &.icon-2 {
                    background-image: url("~@/assets/images/icon_step_02.png");
                  }

                  &.icon-3 {
                    background-image: url("~@/assets/images/icon_step_03.png");
                  }

                  &.icon-4 {
                    background-image: url("~@/assets/images/icon_step_04.png");
                  }

                  &.icon-5 {
                    background-image: url("~@/assets/images/icon_step_05.png");
                  }
                }

                .name {
                  word-break: keep-all;
                }
              }
            }
          }
        }
      }
    }
  }
</style>